import React from "react";
import { Header } from "../Overlay.js";
import "../App.css";
// import img11 from '../components/websiteImagesHome/11.png'

// import { Canvas } from "@react-three/fiber";
// import { ScrollControls, Scroll } from "@react-three/drei";


export const Home = () => (
  <>
    <Header />
    
  </>
);
